import React, { Component } from 'react';
import { Button, Grid, Checkbox, Link, Typography } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/render-text-field';
import Visibility from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';

class SignIn extends Component {

  render() {
    const {
      onCreateAccount,
      onRecoverPassword,
      handleSubmit,
      submitActions,
      alert,
      handleSession
    } = this.props;

    const handleClickSSO = () => {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/signin`
    }

    return (
      <form onSubmit={handleSubmit(submitActions)}>
        <Grid
          className={`animate__animated animate__backInUp`}
          container
          spacing={1}
          style={{ padding: '10px' }}
        >
          <Grid item xs={12} >
            <h3 style={{ marginBottom: '0px' }}>Ingresar</h3>
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Correo"
              name="email"
              required={true}
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Contraseña"
              name="password"
              type="password"
              required
              passwordAction
              icon={
                <Visibility />
              }
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ display: "none" }}>
            <Checkbox color="primary" onChange={(ev) => handleSession(ev)} />
            <small className="validated-text">Mantener mi sesión abierta</small>
          </Grid>

          {
            alert.message &&
            <Grid item md={12} xs={12}>
              <Alert variant="filled" severity={`${alert.type}`}>
                {alert.message}
              </Alert>
            </Grid>
          }

          <Grid item md={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              type="submit"
            >
              Ingresar
            </Button >
          </Grid>

          <Grid item md={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={handleClickSSO}
            >
              Inicio de sesión único (SSO)
            </Button >
          </Grid>

          <Grid item md={12} xs={12}>
            <small className="validated-text text-center">
              ¿Olvidaste tu contraseña?&nbsp;
              <Link
                className="pointer"
                onClick={() => onRecoverPassword()}
                underline='always'
              >
                Reestablécela aquí.
              </Link>
            </small>
          </Grid>

          <Grid item className={'animate__animated animate__backInUp mt-5 pt-3 mb-3'} xs={12}>

          </Grid>
        </Grid>
      </form>
    );
  }

}

const validate = (values) => {
  const errors = {
    email: '',
    password: ''
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (values.email) {
    if (values.email.length > 50) {
      errors.email = 'El correo electrónico no puede ser mayor a 50 caracteres';
    }
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'SignIn',
  validate
})(SignIn);
